<template>
  <v-container>
    <testimonials
      :headers="headers"
      :contents="services"
      title="Testimonials"
      :isNew="true"
      :isIcons="true"
      :icons="['mdi-pencil','mdi-delete']"
      :isOrders="false"
      @editService="editItem($event)"
      @delete="deleteItem($event)"
      @addService=" dialog = true"
    />
    <br />
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.text" label="Review Text"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.image" label="Image URL"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="editedItem.pos" label="Position"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="editedItem.company" label="comapny"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" :loading="loading" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import table from "@/components/Dashboard/table.vue";
import firebase from "firebase";
export default {
  data() {
    return {
      loading: false,
      editedIndex: -1,
      editedItem: {
        name: "",
        text: "",
        pos: "",
        company: "",
        image: "",
      },
      dialog: false,
      services: [
        {
          id: "loading",
          name: "loading",
          text: "loading",
          created_at: "loading",
          pos: "laoding",
          company: "laoding",
          image: "laoding",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "Name", value: "name", sortable: true },
        { text: "pos", value: "pos", sortable: true },
        { text: "company", value: "company", sortable: true },
        { text: "text", value: "text", sortable: true },
        {
          text: "Image",
          value: "image",
          sortable: false,
        },
        { text: "Added On", value: "created_at", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    reset() {
      this.editedItem.name = "";
      this.editedItem.text = "";
      this.editedItem.pos = "";
      this.editedItem.company = "";
      this.editedItem.image = "";

      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.editedItem.name = item.name;
      this.editedItem.text = item.text;
      this.editedItem.pos = item.pos;
      this.editedItem.company = item.company;
      this.editedItem.image = item.image || "";

      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.editedIndex = -1;
    },
    add() {
      this.loading = true;
      this.dialog = true;
      this.$store
        .dispatch("addTestimonails", {
          name: this.editedItem.name,
          text: this.editedItem.text,
          pos: this.editedItem.pos,
          image: this.editedItem.image,
          company: this.editedItem.company,
        })
        .then((res) => {
          this.reset();
        })
        .catch((err) => {
          alert("an error occured "+err);
        });
    },
    save() {
      if (this.editedIndex != -1) {
        this.loading = true;
        this.dialog = true;
        this.$store
          .dispatch("editTestimonials", {
            id: this.editedIndex,
            name: this.editedItem.name,
            text: this.editedItem.text,
            pos: this.editedItem.pos,
            image: this.editedItem.image,
            company: this.editedItem.company,
          })
          .then((res) => {
            this.reset();
          })
          .catch((err) => {
            alert("an error occured "+err);
          });
      } else {
        this.add();
        //JSON.parse(JSON.stringify(this.editedItem))
      }
      this.close();
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete ?")) {
        this.$store
          .dispatch("deleteTestimonials", {
            id: item.id,
          })
          .then((res) => {
            this.reset();
          })
          .catch((err) => {
            alert("an error occured "+err);
          });
      }
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  components: { testimonials: table },
  
  mounted() {
    this.$store.dispatch("subscribeToTestimonials", (services) => {
      this.services = services;
    });
  },
};
</script>
<style>
</style>