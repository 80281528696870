var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.isNew)?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.add()}}},[_vm._v("New Item")]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.contents,"search":_vm.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s())]}},{key:"item.placed_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.placed_at.toDate()))]}},{key:"item.selectedTime",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.selectedTime+' - '+ (item.selectedTime+1)))]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.image,"alt":"image","height":"100px"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isIcons)?_c('div',[_c('v-btn',{on:{"click":function($event){_vm.icons[0]=='mdi-pencil' ? _vm.editItem(item):_vm.blockItem(item)}}},[_vm._v(_vm._s(_vm.icons[0]=='mdi-pencil' ? 'Edit':'Block'))]),_vm._v(" "),_c('v-btn',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("dlt")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons[0]))]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(_vm._s(_vm.icons[1]))])],1):_vm._e(),(_vm.isUser)?_c('div',[_c('v-btn',{on:{"click":function($event){return _vm.blockItem(item)}}},[_vm._v(_vm._s(item.status?item.status=='blocked'?'Active':'Block':'Active'))])],1):_vm._e(),(_vm.isOrders)?_c('div',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.completeDetail(item)}}},[_vm._v("View")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }